
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//grade routes
			{
				path: '/grade/:fieldName?/:fieldValue?',
				name: 'gradelist',
				component: () => import('./pages/grade/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/grade/add', 
				name: 'gradeadd', 
				component: () => import('./pages/grade/add.vue'), 
				props: true
			},
	
			{ 
				path: '/grade/edit/:id', 
				name: 'gradeedit', 
				component: () => import('./pages/grade/edit.vue'), 
				props: true
			},
		

//parents routes
			{
				path: '/parents/:fieldName?/:fieldValue?',
				name: 'parentslist',
				component: () => import('./pages/parents/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/parents/add', 
				name: 'parentsadd', 
				component: () => import('./pages/parents/add.vue'), 
				props: true
			},
	
			{ 
				path: '/parents/edit/:id', 
				name: 'parentsedit', 
				component: () => import('./pages/parents/edit.vue'), 
				props: true
			},
		

//permissions routes

//professors routes
			{
				path: '/professors/:fieldName?/:fieldValue?',
				name: 'professorslist',
				component: () => import('./pages/professors/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/professors/view/:id', 
				name: 'professorsview', 
				component: () => import('./pages/professors/view.vue'), 
				props: true
			},
		
			{ 
				path: '/professors/add', 
				name: 'professorsadd', 
				component: () => import('./pages/professors/add.vue'), 
				props: true
			},
	
			{ 
				path: '/professors/edit/:id', 
				name: 'professorsedit', 
				component: () => import('./pages/professors/edit.vue'), 
				props: true
			},
		

//roles routes

//students routes
			{
				path: '/students/:fieldName?/:fieldValue?',
				name: 'studentslist',
				component: () => import('./pages/students/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/students/view/:id', 
				name: 'studentsview', 
				component: () => import('./pages/students/view.vue'), 
				props: true
			},
		
	
			{ 
				path: '/students/edit/:id', 
				name: 'studentsedit', 
				component: () => import('./pages/students/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

	
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
